import React, { useMemo } from 'react';
import { AuthService, useResizeObserver } from '@innowise-group/core';
import { AppRoutes } from '@constants';
import * as Styled from './auth.styles';
import { AuthDesktopView } from './components/auth-desktop-view';
import { AuthMobileView } from './components/auth-mobile-view';
import { useTheme } from '@innowise-group/mui-kit';
import { useLocation } from 'react-router-dom';

const Auth: React.FC = () => {
  const theme = useTheme();
  const isDesktopView = useResizeObserver(theme.breakpoints.values.lg);

  const { search } = useLocation();
  const redirectUri = useMemo(() => {
    const redirectToParam = new URLSearchParams(search || '').get('returnUrl') || '';
    const rootPath = window.location.origin;
    const routePath =
      !redirectToParam || redirectToParam.indexOf(AppRoutes.Auth) === 0 ? AppRoutes.Home : redirectToParam;
    return `${rootPath}${routePath}`;
  }, [search]);

  const handleSignIn = () => {
    AuthService.login({ redirectUri });
  };

  return (
    <Styled.AuthContainer>
      {isDesktopView ? <AuthDesktopView handleSignIn={handleSignIn} /> : <AuthMobileView handleSignIn={handleSignIn} />}
    </Styled.AuthContainer>
  );
};

export default Auth;
