import { AppRoutes } from '@constants';
import { AuthService } from '@innowise-group/core';
import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { pathname, search } = useLocation();

  const navigationUrl = useMemo(() => {
    return `${AppRoutes.Auth}?returnUrl=${pathname}${search || ''}`;
  }, [pathname, search]);

  const authenticatedValue = AuthService.isLoggedIn();

  return <>{authenticatedValue ? children : <Navigate to={navigationUrl} />}</>;
};

export default PrivateRoute;
