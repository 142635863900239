import { Suspense, useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AppLayout, PrivateRoute } from '@shared-components';
import { AppPages } from './app.router';
import { AppRoutes, ManagerRolesArray } from '@constants';
import { Auth, NotFound } from '@modules';
import { getDocumentTitleInUpperCase, getLanguageFromLS } from '@innowise-group/utilities';
import * as Styled from './app.styles';
import {
  AuthService,
  useNotificationsApi,
  useOptionsApi,
  useProfileAPI,
  useNotificationSubscription,
  useCandidateStatusAPI,
  GoogleAnalyticsService,
  useReferralAPI,
} from '@innowise-group/core';
import { ProfileCreationModal } from './modules/profile/modals/profile-creation-modal';
import { useModal, LoadingFrame, notificationsManager } from '@innowise-group/mui-kit';
import i18n from './i18n';
import { useTranslation } from 'react-i18next';

const App: React.FC = () => {
  useNotificationSubscription();
  const location = useLocation();
  const navigate = useNavigate();
  const [appIsReady, setAppIsReady] = useState(false);
  const [showProfileCreationModal] = useModal(ProfileCreationModal);
  const { fetchProfileData, profile, fetchCurrentEvents, logout } = useProfileAPI();
  const { getReferralsManager } = useReferralAPI();
  const { getUnreadNotificationsCount } = useNotificationsApi();
  const { getAllSelectOptions, getStatusAuthorsByRoles, getCitiesSelectOptions, getCountrySelectOptions, options } =
    useOptionsApi();
  const { getStatusList } = useCandidateStatusAPI();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `ITS RS: ${getDocumentTitleInUpperCase(location.pathname)}`;
  }, [location.pathname]);

  const isRenderShouldBlocked = useMemo(() => {
    const urlSearchParams = new URLSearchParams(location?.search || '');
    const registeredError = urlSearchParams.get('error');

    if (registeredError === 'profileRole') {
      return true;
    }

    if (profile && !profile?.role?.name) {
      return true;
    }

    return false;
  }, [profile]);

  useEffect(() => {
    GoogleAnalyticsService.send({
      hitType: 'pageview',
      page: location.pathname,
    });
  }, [location.pathname]);

  const openRegistrationModal = () => {
    showProfileCreationModal();
  };

  useEffect(() => {
    const lang = getLanguageFromLS();
    i18n.changeLanguage(lang);

    if (profile?.id && i18n?.isInitialized) {
      if (profile?.role) {
        getAllSelectOptions();
        getStatusAuthorsByRoles();
        getCountrySelectOptions();
        getCitiesSelectOptions();
        getStatusList();

        if (!ManagerRolesArray.includes(profile.role.name)) {
          getReferralsManager();
        }

        if (!profile?.registrationCompleted) {
          openRegistrationModal();
          navigate(AppRoutes.Candidates);
        }
      } else {
        logout();
        AuthService.logout({
          redirectUri: `${window.location.origin}${AppRoutes.Auth}?error=profileRole`,
        });
      }
    }
  }, [profile, i18n?.isInitialized]);

  useEffect(() => {
    if (isRenderShouldBlocked && location.pathname === AppRoutes.Auth) {
      notificationsManager.error({
        title: t('pages.auth.brokenProfile.title'),
        subtitle: t('pages.auth.brokenProfile.subtitle'),
      });
    }
  }, [isRenderShouldBlocked, location?.pathname]);

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      fetchProfileData();
      fetchCurrentEvents();
      getUnreadNotificationsCount();
    }
  }, [fetchProfileData, fetchCurrentEvents, getUnreadNotificationsCount, AuthService.isLoggedIn]);

  useEffect(() => {
    const isUserAuthenticated = AuthService.isLoggedIn();
    if (!isUserAuthenticated) setAppIsReady(true);
    if (isUserAuthenticated && options && profile) setAppIsReady(true);
  }, [profile, options]);

  if (!appIsReady && !isRenderShouldBlocked) return <LoadingFrame />;

  return (
    <Styled.VerticalContainer>
      <Routes>
        <Route path={`${AppRoutes.Auth}`} element={<Auth />} />
        {!isRenderShouldBlocked && (
          <Route
            path="*"
            element={
              <PrivateRoute>
                <AppLayout>
                  <Routes>
                    {AppPages.map(({ Component, path }) => (
                      <Route
                        key={path}
                        path={`${path}/*`}
                        element={
                          <Suspense fallback={<LoadingFrame />}>
                            <Component />
                          </Suspense>
                        }
                      />
                    ))}
                    <Route path={`${AppRoutes.Home}/*`} element={<Navigate to={AppRoutes.Candidates} />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </AppLayout>
              </PrivateRoute>
            }
          />
        )}
      </Routes>
    </Styled.VerticalContainer>
  );
};

export default App;
